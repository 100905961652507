import React from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import ComingSoon from "./components/ComingSoon";

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <Helmet>
          {/* Meta tags esenciales */}
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=5, user-scalable=yes"
          />
          <meta name="theme-color" content="#000000" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black" />
          <meta
            name="description"
            content="Descubre el juego de tu alma con 12Mystics: tarot interactivo, consultas de videncia y terapia basada en inteligencia artificial."
          />
          <link rel="apple-touch-icon" href="%PUBLIC_URL%/favicon-new.ico" />
          <title>12Mystics - Tarot, Videncia y Terapia IA</title>
        </Helmet>
        <ComingSoon />
      </div>
    </HelmetProvider>
  );
}

export default App;
