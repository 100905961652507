import React, { useState, useEffect } from "react";
import "./ComingSoon.css";
import videoBackground from "../assets/videos/HomePageHeroVideo.mp4";

const ComingSoon = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const updateWindowSize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  const getDynamicStyles = () => {
    if (windowWidth <= 360) {
      return {
        padding: "0 10px",
      };
    } else if (windowWidth <= 562) {
      return {
        padding: "0 15px",
      };
    } else {
      return {
        padding: "0 20px",
      };
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email) {
      handleSubscription(email);
    } else {
      alert("Por favor, introduce un correo válido.");
    }
  };

  const handleSubscription = async (email) => {
    try {
      const response = await fetch("/api/subscribe", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      const result = await response.json();
      if (response.ok) {
        setMessage(result.message);
        setIsError(false);
      } else {
        setMessage(result.message);
        setIsError(true);
      }
    } catch (error) {
      setMessage("Error al conectarse con el servidor");
      setIsError(true);
    }
  };

  return (
    <main className="hero-container" style={getDynamicStyles()}>
      <video
        className="background-video"
        autoPlay
        loop
        muted
        playsInline
        aria-hidden="true"
      >
        <source src={videoBackground} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="overlay"></div>

      <div className="hero-content">
        <h1 className="animated-text">12Mystics</h1>
        <h2 className="subtitle">Próximamente</h2>

        <section
          className="subscription-section"
          aria-label="Formulario de suscripción"
        >
          <p className="invitation-text fade-in">
            Confía en nuestros expertos en videncia, tarot y rituales sagrados
            <br />
            Con experiencia y sensibilidad, te guiarán hacia tus sueños y el destino que te pertenece
            <br />
            
          </p>

          <p className="discount-offer fade-in">
            ¡Suscríbete ahora y accede a beneficios exclusivos cuando lancemos nuestra plataforma!
          </p>

          <form className="subscription-form fade-in" onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Introduce tu correo electrónico"
              className="email-input"
              value={email}
              onChange={handleInputChange}
              required
            />
            <button type="submit" className="subscribe-button">
              Suscribirme
            </button>
          </form>

          {message && (
            <p
              className={`subscription-message ${
                isError ? "error" : "success"
              } fade-in`}
            >
              {message}
            </p>
          )}
        </section>
      </div>
    </main>
  );
};

export default ComingSoon;
